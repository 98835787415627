<template>
  <a-modal
    width="50%"
    v-model:visible="dialogVisible"
    :title="'选择'+getTypeName(EXERISE, typeId)"
    @cancel="handleCancel"
    @ok="saveSubmit"
    okText="确定"
    cancelText="取消">
    <!-- allowClear
        @change="fetcheEerciseList" -->
    <div class="dialog-header">
      <initSelect class="myselect box-shadow" style="width: 30%; margin-right:20px" v-model:value="tag_id" :list="tagList" placeholder="请选择习题标签"  @change="getListHttp"></initSelect>
      <a-input-search
        v-model:value="search"
        placeholder="请输入习题名、ID"
        style="width: 35%"
        @keyup.enter="getSearchListHttp" />
    </div>
    <div class="content">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="习题列表">
          <!-- :pagination="pagination" -->
          <!-- @change="changePage" -->
          <a-table
            size="small"
            :columns="columns"
            :data-source="data"
            class="table"
            rowKey="id"
            :pagination="pagination"
            @change="changePage"
            :row-selection="{...rowSelection}"
            :scroll="{y: 400}"
            >
              <template #action1="{record}">
                <EyeOutlined @click="handlePreview(record)"/>
              </template>
            </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="已选列表">
          <a-table
            :scroll="{y: 400}"
            size="small"
            :columns="columnsAll"
            :data-source="rowSelection.selectedRows"
            class="table"
            rowKey="id">
              <!-- <template #tag="{record}">
                  <span>{{record.tag}}</span>
              </template> -->
              <template #content="{record}">
                  <span>{{record.content ? record.content.question.text : record.text}}</span>
              </template>
            </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
      <template #footer>
        <a-button @click="handleCancel" style="margin-right:10px">取消</a-button>
        <a-badge :count="rowSelection.selectedRowKeys.length">
          <a-button type="primary" @click="saveSubmit">确认</a-button>
        </a-badge>
      </template>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, onMounted, computed, watch } from 'vue'
import { EXERISE } from '@/hooks/config'
import { screenObj, filterKeys } from '@/hooks/common-hooks'
import { exerciseList, putExam, exerciseTag } from '@/api/index'
import initSelect from '@/components/select.vue'
import { EyeOutlined } from '@ant-design/icons-vue'
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: '习题标签',
    dataIndex: 'tag',
    key: 'tag'
  },
  {
    title: '题干',
    dataIndex: 'text',
    key: 'text',
    width: 300
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
const columnsAll = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: '习题标签',
    dataIndex: 'tag',
    key: 'tag'
  },
  {
    title: '习题名',
    dataIndex: 'content',
    key: 'content',
    slots: { customRender: 'content' },
    width: 300
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
// interface PaginationProps {
//   current: number;
//   pageSize: number;
//   total: number;
// }
const pagination = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  props: ['visible', 'examId', 'dataObj'],
  emits: ['cancel', 'openPreview'],
  components: {
    initSelect,
    EyeOutlined
  },
  setup (props, context) {
    // console.log(props.type, '1111111111')
    const dialogVisible = computed(() => props.visible)
    const tableData = reactive({
      columns,
      columnsAll,
      data: [],
      pagination,
      version: '',
      schoolId: null,
      typeId: 1,
      tag_id: null,
      EXERISE,
      search: '',
      tagList: []
    })
    const getExamTag = (callback) => {
      exerciseTag({}).then(res => {
        tableData.tagList = res.data
        tableData.tag_id = res.data[0].id
        callback && callback()
      })
    }
    const handleCancel = () => {
      context.emit('cancel')
    }
    const rowSelection = reactive({
      columnWidth: 50,
      selectedRowKeys: [], // 指定选中项的 key 数组
      selectedRows: [],
      onChange: (selectedRowKeys, selectedRows) => {
        rowSelection.selectedRowKeys = selectedRowKeys
        if (rowSelection.selectedRows.length === 0) {
          rowSelection.selectedRows = selectedRows
        } else {
          rowSelection.selectedRows = rowSelection.selectedRows.concat(selectedRows.filter(item => !rowSelection.selectedRows.find(items => item.id === items.id)))
        }
        rowSelection.selectedRows = rowSelection.selectedRows.filter((item) => selectedRowKeys.indexOf(item.id) !== -1)
      }
    })
    const getSwitchData = (ispage) => {
      if (ispage) return
      switch (tableData.typeId) {
        case 1:
          rowSelection.selectedRowKeys = props.dataObj.choice_ids ? props.dataObj.choice_ids.split(',').map(Number) : []
          rowSelection.selectedRows = props.dataObj.choice_exercise === null ? [] : props.dataObj.choice_exercise
          break
        case 2:
          rowSelection.selectedRowKeys = props.dataObj.true_ids ? props.dataObj.true_ids.split(',').map(Number) : []
          rowSelection.selectedRows = props.dataObj.true_exercise === null ? [] : props.dataObj.true_exercise
          break
        case 3:
          rowSelection.selectedRowKeys = props.dataObj.line_ids ? props.dataObj.line_ids.split(',').map(Number) : []
          rowSelection.selectedRows = props.dataObj.line_exercise === null ? [] : props.dataObj.line_exercise
          break
        default:
          break
      }
    }
    const getListHttp = (callback) => {
      exerciseList(screenObj({
        sid: tableData.schoolId,
        type: tableData.typeId,
        tag: tableData.tag_id,
        search: tableData.search,
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      })).then(res => {
        tableData.data = res.data
        tableData.pagination.total = res.result
        if (callback) {
          callback()
        }
      })
    }
    const getSearchListHttp = () => {
      tableData.pagination.current = 1
      getListHttp()
    }
    // 获取习题列表
    const fetcheEerciseList = (isPage) => {
      getListHttp(() => {
        getSwitchData(isPage)
      })
    }
    // 翻页
    const changePage = (pagination) => {
      tableData.pagination.current = pagination.current
      fetcheEerciseList(true)
    }
    const getTypeName = (data, type) => {
      console.log(data, type, 'cccccccccccc')
      return data.filter(item => {
        return item.id === type
      })[0].name
    }
    // 获取选中的id
    const getKeyIds = () => {
      return rowSelection.selectedRows.map(item => item.id).toString()
    }
    const getIds = (data) => {
      return data ? data.split(',').map(Number) : []
    }
    // 确认提交
    const saveSubmit = () => {
      let obj = {
        id: '',
        choice_ids: [],
        true_ids: [],
        line_ids: []
      }
      filterKeys(obj, props.dataObj)
      switch (tableData.typeId) {
        case 1:
          obj = Object.assign(obj, {
            choice_ids: getKeyIds()
          })
          break
        case 2:
          obj = Object.assign(obj, {
            true_ids: getKeyIds()
          })
          break
        case 3:
          obj = Object.assign(obj, {
            line_ids: getKeyIds()
          })
          break
        default:
          break
      }
      putExam(obj).then(res => {
        handleCancel()
        console.log(res)
      })
    }
    // 预览
    const handlePreview = (record) => {
      context.emit('openPreview', record.id)
    }
    watch(() => props.visible, (newVal) => {
      if (newVal) {
        getExamTag(() => {
          getListHttp()
        })
      } else {
        tableData.search = ''
        pagination.current = 1
      }
    })
    return {
      getListHttp,
      // visible: false,
      // tagList,
      dialogVisible,
      ...toRefs(tableData),
      value: '',
      changePage,
      handleCancel,
      fetcheEerciseList,
      rowSelection,
      getTypeName,
      saveSubmit,
      activeKey: ref('1'),
      handlePreview,
      getSearchListHttp
    }
  }
})
</script>
<style lang="scss" scoped>
.dialog-header{
  display: flex;
  align-items: center;
  // justify-content: space-between;
}
.content{
  height: 580px;
}
</style>
