
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { message } from 'ant-design-vue'
import { fetchExamDetail, putExam } from '@/api/index'
import editExamDialog from './components/editExamDialog.vue'
import { PlusCircleFilled, DeleteOutlined, PlayCircleFilled } from '@ant-design/icons-vue'
import { OSSURL } from '@/hooks/ali-oss'
import previewDialog from './components/previewDialog.vue'
import { EXERISE } from '@/hooks/config'
import { useRouter } from 'vue-router'
import matchTemp from './components/matchTemp.vue'
const typeList = [{
  id: 1,
  type: '选择题',
  total: 4
}, {
  id: 2,
  type: '判断题',
  total: 3
}, {
  id: 3,
  type: '连线题',
  total: 3
}]
const keyList = ['A', 'B', 'C', 'D']
export default defineComponent({
  name: 'editExam',
  props: ['id'],
  components: { matchTemp, PlusCircleFilled, DeleteOutlined, editExamDialog, previewDialog, PlayCircleFilled },
  setup (props) {
    const router = useRouter()
    const visible = ref<boolean>(false)
    const videoUrl = ref()
    const videoVisible = ref<boolean>(false)
    const selectType = ref(null)
    const dataObj = ref<any>({})
    const examDialogRef = ref()
    const examData: any[] = reactive([{
      type: 1,
      name: '选择题',
      list: []
    }, {
      type: 2,
      name: '判断题',
      list: []
    }, {
      type: 3,
      name: '连线题',
      list: []
    }])
    const getIndexKey = (item: any, listIndex: number) => {
      const indexKey = ref(0)
      switch (item.type) {
        case 1:
          indexKey.value = listIndex + 1
          break
        case 2:
          indexKey.value = examData[0].list.length + 1 + listIndex
          break
        case 3:
          indexKey.value = examData[0].list.length + examData[1].list.length + 1 + listIndex
          break
      }
      return indexKey.value
    }
    const getHeight = (listIndex: number, index: number) => {
      const _arr = examData[2].list[listIndex].content.answers.item1
      if (_arr) {
        if (_arr[index].image) {
          return 130
        } else {
          return ''
        }
      }
    }
    const getHeightLeft = (listIndex: number, index: number) => {
      const _arr = examData[2].list[listIndex].content.answers.item2
      if (_arr) {
        if (_arr[index].image) {
          return 130
        } else {
          return ''
        }
      }
    }

    // 获取试卷详情
    const getExamDetail = () => {
      fetchExamDetail({ id: props.id }).then(res => {
        console.log(res)
        dataObj.value = res.data
        examData[0].list = res.data.choice_exercise ? res.data.choice_exercise.map((item: any) => {
          item.content = JSON.parse(item.content)
          return item
        }) : []
        examData[1].list = res.data.true_exercise ? res.data.true_exercise.map((item: any) => {
          item.content = JSON.parse(item.content)
          return item
        }) : []
        examData[2].list = res.data.line_exercise ? res.data.line_exercise.map((item: any) => {
          item.content = JSON.parse(item.content)
          return item
        }) : []
        console.log(examData)
        // typeList[0].total = res.data.choice_exercise ? res.data.choice_exercis.length : 0
      })
    }
    const getExeNums = (data: any) => {
      if (data) {
        return data.length
      } else {
        return 0
      }
    }
    // 打开弹框
    const openDialog = (type: any) => {
      examDialogRef.value.typeId = type
      visible.value = true
      // examDialogRef.value.fetcheEerciseList()
    }
    // 关闭弹框
    const dialogCancle = () => {
      visible.value = false
      getExamDetail()
    }
    const getIndex = (arr: any) => {
      const _index = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].right === true) {
          _index.push(i)
        }
      }
      return _index
    }
    const getAnser = (data: any) => {
      const _arr: any = []
      if (data.answers) {
        if (data.answers === true) {
          return '正确'
        } else {
          getIndex(data.answers).map((item: any) => {
            _arr.push(keyList[item])
          })
          return _arr.join()
        }
      } else {
        if (data.answers === false) {
          return '错误'
        }
      }
    }
    // 视频预览
    const lookVideo = (url: string) => {
      videoVisible.value = true
      videoUrl.value = url
    }
    // 跳转对应的页面进行编辑
    const handleEdit = (record: any) => {
      const name = EXERISE.find(item => item.id === record.type)
      router.push({
        name: name?.router,
        params: { id: record.id }
      })
    }
    // 获取选中的id
    const getKeyIds = (val: any, arr: any) => {
      return arr.filter((item: any) => {
        return item.id !== val.id
      }).map((item: any) => item.id)
    }
    const getIds = (data: any) => {
      return data.split(',').map(Number)[0] > 0 ? data.split(',').map(Number) : []
    }
    // 批量删除
    const batckDelete = (type: number) => {
      putExam({
        id: parseInt(props.id),
        choice_ids: EXERISE[0].id === type ? '' : getIds(dataObj.value.choice_ids).toString(),
        true_ids: EXERISE[1].id === type ? '' : getIds(dataObj.value.true_ids).toString(),
        line_ids: EXERISE[2].id === type ? '' : getIds(dataObj.value.line_ids).toString()
      }).then(res => {
        message.success('删除成功')
        getExamDetail()
        console.log(res)
      })
    }
    // 单个删除
    const onlyDelete = (item: any) => {
      putExam({
        id: parseInt(props.id),
        choice_ids: (EXERISE[0].id === item.type ? getKeyIds(item, dataObj.value.choice_exercise) : getIds(dataObj.value.choice_ids)).toString(),
        true_ids: (EXERISE[1].id === item.type ? getKeyIds(item, dataObj.value.true_exercise) : getIds(dataObj.value.true_ids)).toString(),
        line_ids: (EXERISE[2].id === item.type ? getKeyIds(item, dataObj.value.line_exercise) : getIds(dataObj.value.line_ids)).toString()
      }).then(res => {
        message.success('删除成功')
        getExamDetail()
        console.log(res)
      })
    }
    const isShow = () => {
      if (dataObj.value.choice_exercise === null || dataObj.value.true_exercise === null || dataObj.value.line_exercise === null) {
        return true
      } else {
        return false
      }
    }
    const getSortIds = (arr: any) => {
      return arr.map((item: any) => item.id)
    }
    // 上下移动后排序
    const sortExam = (item: any, data: any) => {
      putExam({
        id: parseInt(props.id),
        choice_ids: (EXERISE[0].id === item.type ? getSortIds(data) : getIds(dataObj.value.choice_ids)).toString(),
        true_ids: (EXERISE[1].id === item.type ? getSortIds(data) : getIds(dataObj.value.true_ids)).toString(),
        line_ids: (EXERISE[2].id === item.type ? getSortIds(data) : getIds(dataObj.value.line_ids)).toString()
      }).then(res => {
        getExamDetail()
        console.log(res)
      })
    }
    // 上移
    const moveUp = (index: number, listIndex: number) => {
      const arr = examData[index].list
      console.log(listIndex)
      if (listIndex === 0) {
        return message.warning('已经是第一个了')
      }
      arr[listIndex] = arr.splice(listIndex - 1, 1, arr[listIndex])[0]
      sortExam(arr[listIndex], arr)
    }
    // 下移
    const moveDown = (index: number, listIndex: number) => {
      const arr = examData[index].list
      console.log(listIndex)
      if (listIndex === arr.length - 1) {
        return message.warning('已经是最后一个了')
      }
      arr[listIndex] = arr.splice(listIndex + 1, 1, arr[listIndex])[0]
      sortExam(arr[listIndex], arr)
      console.log(arr)
    }
    // 预览弹出层
    const previewVisible = ref(false)
    const previewId = ref(0)
    const openPreview = (id: number) => {
      previewId.value = id
      previewVisible.value = true
    }
    onMounted(() => {
      if (props.id) {
        getExamDetail()
      }
    })
    return {
      getHeightLeft,
      onlyDelete,
      batckDelete,
      handleEdit,
      lookVideo,
      videoVisible,
      videoUrl,
      typeList,
      isShow,
      examData,
      getIndexKey,
      moveUp,
      moveDown,
      getHeight,
      dataObj,
      getExeNums,
      visible,
      dialogCancle,
      openDialog,
      examDialogRef,
      selectType,
      OSSURL,
      keyList,
      getAnser,
      previewVisible,
      openPreview,
      previewId
    }
  }
})
